<template>
<v-container>
  <div>
    <navbar title="جزئیات مرسوله"/>
      <v-main class="pt-15">
        <div v-for="item in cargoInfo" :key="item.id" >
         <product :cargoInfo='item' imageSize="85"/>
        </div>
     </v-main>
  </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import product from './components/cards/orderProduct'
import {viewCargo} from './models/factors'
import { mapState } from 'vuex'
export default {
  props: ['carogID','factoryID'],
    components: {
        navbar,
        product,
    },
    computed : {
      ...mapState({
        cargoInfo: state => state.factors.cargoItems
      })
    },
    created () {
      viewCargo(this.$props.carogID)
    }
}
</script>