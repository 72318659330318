<template>
  <div>
    <v-card v-for="item in items" :key="item.id" class="mt-4 card flat-card pa-2" color="surface">
     <v-row>
         <v-col cols="4" class="pl-0">
          <v-list-item-avatar size="90" tile class="mr-1">
          <v-img :src="item.image"/>
         </v-list-item-avatar>
        </v-col>
         <v-col cols="8" class="my-auto">
            <div class="mt-2">
            <span class="medium-font onSurface--text">نام محصول:</span>
            <span class="medium-font mr-1 onSurface--text" v-text="item.title.slice(0,50)"><span v-if="item.title > item.title.slice(0,50)" >...</span></span>
            </div>
            <div>
            <span v-if="item.is_bulk != 1" class="medium-font onSurface--text">تعداد:</span>
            <span v-if="item.is_bulk == 1" class="medium-font onSurface--text">مقدار:</span>
            <span class="medium-font mr-1 onSurface--text" v-text="item.count"></span>
            </div>
            <div>
            <span class="medium-font onSurface--text">قیمت واحد:</span>
            <span class="medium-font mr-1 onSurface--text" v-text="item.price"></span>
            </div>
            
          <div>
            <span class="medium-font onSurface--text">مبلغ:</span>
              <span class="medium-font onSurface--text" v-text="item.toll_price"></span>
          </div>
        </v-col> 
     </v-row>
     <v-divider></v-divider>
    <v-card-actions>
        <span class="medium-font onSurface--text mr-2">تامین کننده:</span>
        <v-spacer></v-spacer>
        <span class="medium-font onSurface--text mr-auto" v-text="item.org_title"></span>
    </v-card-actions>
   </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      items: state => state.factors.cargoItems
    })
  }
}
</script>